import React, { useState, useContext } from 'react';
import { Button } from '@mymoons/ui-library';
import PropTypes from 'prop-types';
import Text from '../Text';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './Header.module.css';
import {
  logoMoons,
  arrow,
} from '../../assets/images/header';
import { backCheckout } from '../../assets/images/Global';
import AlertModal from '../AlertModal';
import GlobalContext from '../../context/Global/Context';
import { getTotalPrice } from '../../utils/cardForm';
import PaymentContext from '../../context/Payment/Context';

const Header = ({
  isThankYouPage,
  currency,
  date,
}) => {
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const {
    redirectUrl,
    isCitaFlow,
    origin,
  } = globalContext;
  const tyDataCard = JSON.parse(localStorage.getItem('tyDataCard'));
  const country = localStorage.getItem('country');
  const dataPreventModal = {
    title: '¿Salir sin comprar?',
    description:
      '¿Estás seguro que deseas salir del proceso de compra? Si lo haces, deberás comenzar desde el inicio el llenado de tu información.',
    button: 'Aceptar y salir',
    discard: 'Permanecer aquí',
    imgAlert: backCheckout,
    urlRedirect: redirectUrl,
  };

  return (
    <div className={styles.header}>
      {showAlertModal && (
        <AlertModal data={dataPreventModal} modal={setShowAlertModal} />
      )}
      {!isThankYouPage && !isCitaFlow && origin !== 'payment-links' && (
        <button
          id="Check_Header_BackButtonPrimary"
          onClick={() => {
            setShowAlertModal(true);
          }}
          type="button"
          className={styles.button}
        >
          <img
            width={24}
            height={24}
            className={styles.arrow}
            src={arrow}
            alt="Back Arrow"
          />
          {!isMobile && (
            <Button
              id="Check_Header_BackButton"
              className={styles.back}
              label="Regresar"
              variant="text"
            />
          )}
        </button>
      )}
      <img width={106} height={24} src={logoMoons} alt="Logo Moons" />
      {isThankYouPage ? (
        <div className={styles.totalContainer}>
          <Text w={400} c="#757575" s={10} lh={10} p="4px 0">
            Total
            {' '}
            $
            <Text f="Inter Medium" s={10} lh={10}>
              {tyDataCard !== null
                ? getTotalPrice(paymentContext, country)
                : getTotalPrice(paymentContext, country)}
              {' '}
              {currency}
            </Text>
          </Text>
          <Text w={400} c="#757575" s={10} lh={10}>
            {date}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  isThankYouPage: PropTypes.bool,
  currency: PropTypes.string,
  date: PropTypes.string,
};

Header.defaultProps = {
  isThankYouPage: false,
  currency: '',
  date: '',
};

export default Header;
