/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import styles from '../../MethodsFlow.module.css';
import classNames from 'classnames';
import PaymentContext from '../../../../../../context/Payment/Context';
import Constants from '../../../../../../utils/Constants';

const { assetsURL } = Constants;
const SliderButton = ({ data }) => {
  const paymentContext = useContext(PaymentContext);

  const { updateDashboard, dashboard, hasMultiplePayments } = paymentContext;

  const { icon, label, type } = data;
  const methodClasses = classNames({
    [styles.method]: true,
    [styles.activeMethod]: dashboard == type,
  });

  useEffect(() => {
    updateDashboard('card');
  }, [hasMultiplePayments]);

  return (
    <div
      className={methodClasses}
      onClick={() => {
        updateDashboard(type);
      }}
    >
      <img
        width="24"
        height="24"
        className={dashboard == type ? styles.redIcon : ''}
        src={`${assetsURL}/svg/${icon}${dashboard == type ? '-red' : ''}.svg`}
        alt={type}
      />
      <div>{label}</div>
    </div>
  );
};

export default SliderButton;
