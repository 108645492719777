const data = {
  titleSelect: 'Tarjetas guardadas',
  titleCreate: 'Nueva tarjeta',
  titleEdit: 'Editar Tarjeta',
  name: 'Nombre del titular de la tarjeta',
  nameHolder: 'Nombre y apellidos',
  info: 'Número de tarjeta',
  cardHolder: '4242 4242 4242 4242',
  monthHolder: '01/29',
  cvcHolder: '1234',
  save: 'Deseo guardar esta tarjeta.',
  list: 'LISTA DE TARJETAS GUARDADAS',
  buttonText: 'Agregar',
  tipoDocumento: 'Tipo de documento',
  numDocumento: 'Número del documento',
  holderDocumento: 'Ingresa el número del documento',
  document: {
    PE: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'RUC',
        selected: false,
        value: 'RUC',
        regex: 'num',
      },
      {
        id: 2,
        label: 'DNI',
        selected: false,
        value: 'DNI',
        regex: 'num',
      },
    ],
  },
  clientType: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: null,
    },
    {
      id: 1,
      label: 'Persona Natural',
      selected: false,
      value: 'DNI',
    },
    {
      id: 2,
      label: 'Persona Jurídica',
      selected: false,
      value: 'RUC',
    },
  ],
};

module.exports = data;
