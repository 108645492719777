import axios from 'axios';
import { Buffer } from 'buffer';

/**
 *
 * @returns {object} Deal Appointment Data
 */

const basicAuthHeader = () => {
  const { REACT_APP_BASIC_AUTH_USERNAME, REACT_APP_BASIC_AUTH_PASSWORD } = process.env;
  const user = REACT_APP_BASIC_AUTH_USERNAME;
  const password = REACT_APP_BASIC_AUTH_PASSWORD;
  const auth = `${user}:${password}`;
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`;
  return {
    Authorization: authEncoded,
    'Content-Type': 'application/json',
  };
};

export const getPatientDataBack4app = (table, publicKey, order, customerId) => {
  const { REACT_APP_MIDDLEWARE_DATABASE } = process.env;
  const options = {
    headers: basicAuthHeader(),
    method: 'POST',
  };

  const data = {
    table,
    columns: {
    },
    order,
  };

  if (customerId) {
    data.columns.CustomerId = customerId;
  } else if (publicKey) {
    data.columns.PublicKey = publicKey;
  } else {
    throw new Error('No public key or customer id provided');
  }

  return axios.post(`${REACT_APP_MIDDLEWARE_DATABASE}`, data, options);
};

export const getSmileCenterBack4app = (calendarId, table = 'SmileCenter') => {
  const { REACT_APP_MIDDLEWARE_DATABASE } = process.env;
  const options = {
    headers: basicAuthHeader(),
    method: 'POST',
  };

  const data = {
    table,
    columns: {
      Calendar_Id: calendarId,
    },
  };

  return axios.post(`${REACT_APP_MIDDLEWARE_DATABASE}`, data, options);
};

export const getReferralCoupon = (code) => {
  const { REACT_APP_MIDDLEWARE_DATABASE } = process.env;
  const options = {
    headers: basicAuthHeader(),
    method: 'POST',
  };

  const data = {
    table: 'ReferralCoupon',
    columns: {
      Code: code,
    },
  };

  return axios.post(`${REACT_APP_MIDDLEWARE_DATABASE}`, data, options);
};

export const getCouponInternal = (code) => {
  const { REACT_APP_MIDDLEWARE_DATABASE } = process.env;
  const options = {
    headers: basicAuthHeader(),
    method: 'POST',
  };

  const data = {
    table: 'Coupon',
    columns: {
      Code: code,
    },
  };

  return axios.post(`${REACT_APP_MIDDLEWARE_DATABASE}`, data, options);
};
