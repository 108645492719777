/* eslint-disable */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Box, Text } from '../../../../components';
import styles from '../../Summary.module.css'
import { Button, Input, Banner } from '@mymoons/ui-library';
import AlertModal from '../../../../components/AlertModal';
import { close, backCheckout } from '../../../../assets/images/Global';
import GlobalContext from '../../../../context/Global/Context';
import PaymentContext from '../../../../context/Payment/Context';
import ProductContext from '../../../../context/Product/Context';
import { axiosPutSetAmountToBePaid } from '../../../../methods/globalData/axiosPut';
import totalPriceCarts from '../../../../hooks/totalCarts';

const MultiplePayment = () => {
    const globalContext = useContext(GlobalContext);
    const paymentContext = useContext(PaymentContext);
    const productContext = useContext(ProductContext);
    const {
        productsMobile,
        setTotalPrice,
        totalPrice,
        setAmountToBePaid: setAmountToBePaidContext,
        hasMultiplePayments,
        setHasMultiplePayments
    } = paymentContext;
    const { carts: cartsCtxt, productsWithDiscount } = productContext;
    const totalPriceFromCart = totalPriceCarts(cartsCtxt, false);
    const carts = new URLSearchParams(window.location.search).getAll('cart');
    const [isChevronChecked, setIsChevronChecked] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { redirectUrl, customerId, country } = globalContext;
    const [amountError, setAmountError] = useState(null);
    const [amountToBePaid, setAmountToBePaid] = useState('');
    const [showApplyButton, setShowApplyButton] = useState(false);
    const cartId = carts[0];
    const [loader, setLoader] = useState(false);
    const $body = document.querySelector('body');
    const $html = document.querySelector('html');
    let scrollPosition = 0;
    const dataPreventModal = {
        title: 'Modificación en el precio de tu carrito',
        description:
            'Hemos actualizado el precio de tu pedido. Continúa con tu compra.',
        button: 'Confirmar',
        discard: 'Permanecer aquí',
        imgAlert: backCheckout,
        urlRedirect: redirectUrl,
        onlyPrimaryButton: true,
        isButtonPrimaryAction: true,
    };


    const minimumAmountByCountry = (country) => {
        const minimumAmount = {
            MX: 700,
            CO: 120000,
            PE: 155,
        }
        return minimumAmount[country];
    }

    const maximumAmountByCountry = (country, price) => {
        const maximumAmount = {
            MX: price - 700,
            CO: price - 120000,
            PE: price - 155,
        }

        return maximumAmount[country];
    }


    const handleSubmit = useCallback(() => {
        setAmountError(null);
        setLoader(true);
        const amount = parseFloat(amountToBePaid);
        const minimumAmount = minimumAmountByCountry(country, amount);
        const maximumAmount = maximumAmountByCountry(country, totalPriceFromCart);
        if (amount < minimumAmount) {
            setAmountError(`El monto mínimo para dividir tu pago es de $${minimumAmount}`);
            setLoader(false);
            return;
        } else if (amount > maximumAmount) {
            setAmountError(`El monto máximo para dividir tu pago es de $${maximumAmount}`);
            setLoader(false);
            return;
        }
        if (!isNaN(amount)) {
            axiosPutSetAmountToBePaid(cartId, amount, true)
                .then(async (res) => {
                    setShowAlertModal(true);
                    setTotalPrice(`$${amountToBePaid}`);
                    setShowApplyButton(true);
                    paymentContext.resetCardForm();
                    setLoader(false);
                    setAmountToBePaid('');
                    setAmountToBePaidContext('');
                    setHasMultiplePayments(true);
                });
        }
    }, [amountToBePaid, carts]);

    const handleDelete = useCallback(() => {
        setAmountError(null);
        setLoader(true);
        axiosPutSetAmountToBePaid(cartId, 0, false)
            .then(async (res) => {
                setShowAlertModal(true);
                setTotalPrice(`$${totalPriceFromCart}`);
                setShowApplyButton(false);
                paymentContext.resetCardForm();
                setLoader(false);
                setAmountToBePaid('');
                setAmountToBePaidContext('');
                setHasMultiplePayments(false);
            });
    }, [amountToBePaid]);

    const handleChevronChange = (event) => {
        setIsChevronChecked(event.target.checked);
    };

    useEffect(() => {
        if (showAlertModal) {
            $html.style.height = '100vh';
            scrollPosition = window.pageYOffset;
            $body.style.overflow = 'hidden';
            $body.style.position = 'fixed';
            $body.style.top = `-${scrollPosition}px`;
            $body.style.width = '100%';
            return;
        }

        $html.style.removeProperty('height');
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    }, [showAlertModal]);

    const validateMinimumPriceByCountry = (price, country) => {
        const validation = {
            MX: price >= 7000,
            CO: price >= 1200000,
            PE: price >= 1550,
        }
        return validation[country];
    }

    return (
        <>
            {showAlertModal && (
                <AlertModal data={dataPreventModal} modal={setShowAlertModal} />
            )}
            {validateMinimumPriceByCountry(totalPriceFromCart, country) && (
                <>
                    {
                        cartsCtxt[0].cartState === 'Active' && (
                            <div className={styles.formMultiplePayments}>
                                <Box>
                                    <div className={styles.paymentMethodsToggle}>
                                        <p className={styles.label}>¿Deseas pagar con 2 métodos de pago?</p>
                                        <label className={styles.container}>
                                            <input defaultChecked={false} type="checkbox" onChange={handleChevronChange} />
                                            <svg viewBox="0 0 512 512" height="1em" xmlns="http://www.w3.org/2000/svg"   className={isChevronChecked ? styles.chevronUp : styles.chevronDown}>
                                                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    {isChevronChecked &&
                                        <Box>
                                            <div className={styles.disclaimer}>
                                                <Banner
                                                    content="Por favor corrobora que tus pagos se hayan realizado correctamente. 
              Primero realiza el pago con el método de pago principal y después con el método de pago secundario.
              Si tienes un cupón, asegurate de usarlo durante el primer pago."
                                                    fullWidth
                                                    title="Atención"
                                                    variant="default"
                                                />
                                            </div>
                                            <br />
                                            {!hasMultiplePayments ? (
                                                <div className={styles.containerPayments}>
                                                    <Input
                                                        id="multiplePaymentInput"
                                                        className={styles.inputMultiplePayment}
                                                        error={amountError}
                                                        iconVariant="regular"
                                                        label=""
                                                        placeholder="Ingresar cantidad"
                                                        value={amountToBePaid}
                                                        variant="outline"
                                                        fullWidth
                                                        pattern="[0-9\-]+"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const numericValue = value.replace(/[^0-9]/g, '');
                                                            const intValue = parseInt(numericValue, 10);
                                                            if (!isNaN(intValue)) {
                                                                if (intValue <= totalPriceFromCart) {
                                                                    setAmountToBePaid(intValue.toString());
                                                                    setAmountToBePaidContext(intValue.toString());
                                                                }
                                                            } else {
                                                                setAmountToBePaid('');
                                                                setAmountToBePaidContext('');
                                                            }
                                                        }}
                                                    />
                                                    <Button
                                                        id="Check_Summary_AddMultiplePayment_Action"
                                                        className={styles.multiplePaymentButton}
                                                        color="red"
                                                        label="Actualizar precio"
                                                        isButton
                                                        rel="noopener noreferrer"
                                                        size="small"
                                                        variant="text"
                                                        loading={loader}
                                                        onClick={handleSubmit}
                                                        disabled={amountToBePaid === '' || totalPriceFromCart < amountToBePaid}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={styles.containerPayments}>
                                                    <Button
                                                        id="Check_Summary_AddMultiplePayment_Action"
                                                        className={styles.multiplePaymentButton}
                                                        color="red"
                                                        label="Limpiar monto"
                                                        isButton
                                                        rel="noopener noreferrer"
                                                        size="small"
                                                        variant="filled"
                                                        loading={loader}
                                                        onClick={handleDelete}
                                                    />
                                                    <br />
                                                </div>
                                            )}

                                        </Box>
                                    }
                                </Box>
                            </div>
                        )
                    }
                    {cartsCtxt[0].cartState !== 'Active' && cartsCtxt[0].custom.fields.remanent && (
                        <Box>
                            <div className={styles.disclaimer}>
                                <Banner
                                    content="Por favor continua con tu segundo pago para completar tu compra."
                                    fullWidth
                                    title="Atención"
                                    variant="default"
                                />
                            </div>
                        </Box>
                    )}
                </>
            )}
        </>
    )
};

export default MultiplePayment;
