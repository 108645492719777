import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(timezone);

function capitalize(str) {
  try {
    const lower = str.toLowerCase();
    const strSplit = lower.split(' ');
    if (strSplit.length > 3) {
      let chars = strSplit[0].split('');
      chars[0] = chars[0].toUpperCase();
      strSplit[0] = chars.join('');
      chars = strSplit[3].split('');
      chars[0] = chars[0].toUpperCase();
      strSplit[3] = chars.join('');
    }
    return strSplit.join(' ');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return str;
}

const countryFormat = {
  México: 'es-mx',
  Colombia: 'es-co',
  Peru: 'es-pe',
};

const dateFormatter = (date, timeZone, country) => {
  return capitalize(dayjs.utc(date).tz(timeZone).locale(countryFormat[country] || 'es-mx').format('dddd, DD [de] MMMM - hh:mm A'));
};
export default dateFormatter;
