const formPSE = {
  clientType: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: null,
    },
    {
      id: 1,
      label: 'Persona Natural',
      selected: false,
      value: 'N',
    },
    {
      id: 2,
      label: 'Persona Jurídica',
      selected: false,
      value: 'J',
    },
  ],
  document: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: 0,
      regex: null,
    },
    {
      id: 1,
      label: 'Cédula de ciudadanía',
      selected: false,
      value: 'CC',
      regex: 'num',
    },
    {
      id: 2,
      label: 'Cédula de extranjería',
      selected: false,
      value: 'CE',
      regex: 'alfa',
    },
    {
      id: 3,
      label: 'Número de Identificación Tributario',
      selected: false,
      value: 'NIT',
      regex: 'num',
    },
  ],
};

export default formPSE;
