/**
 * This function return string number with commas and erase decimals if not exists
 * @param {string} number
 * @param {string} country
 * @returns {string} Price with commas
 */

export const numberWithCommas = (number, country) => {
  let numberConverted = Number(number);
  if (numberConverted % 1 === 0) {
    numberConverted = Math.trunc(numberConverted);
  }

  let formatedNumberWithCommas = numberConverted
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (country === 'MX' || country === 'PE') {
    formatedNumberWithCommas = numberConverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (country === 'CO' || country === 'CL') {
    formatedNumberWithCommas = numberConverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return formatedNumberWithCommas;
};

// /**
//  * This function retun the current price with Installments amount
//  * @param {string} price
//  * @param {number} months
//  * @returns {string} Final price
//  */

// export const getPriceWithInstallments = (price, months) => {
//   const installments = paymentInstallments.filter((payment) => {
//     return payment.months === months;
//   });

//   if ((months >= 3 && months <= 6) && +price >= +process.env.REACT_APP_MSI_AMOUNT) {
//     return (+price / months);
//   }
//   return ((+price * (1 + installments[0].taxe)) / months).toFixed(2);
// };

/**
 * This function retun the current price
 * @param {Object} paymentCtxt
 * @param {string} country
 * @returns {string} Total  price
 */

export const getTotalPrice = (paymentCtxt, country) => {
  if (!paymentCtxt.totalPrice) {
    return '';
  }
  const totalPrice = paymentCtxt.totalPrice.split('$')[1];
  const priceDecimal = totalPrice.replaceAll(',', '');
  let finalPrice = priceDecimal;
  const tax = paymentCtxt.installmentsTax * 0.01;
  finalPrice = priceDecimal * (1 + tax);
  finalPrice = finalPrice.toFixed(2);
  return numberWithCommas(finalPrice, country);
};

/**
* This function retun the current price
* @param {string} price
* @param {number} months
* @param {string} country
* @returns {string} Total  price
*/
export const getTotalPriceFormatted = (price, country) => {
  if (!price) {
    return '';
  }
  const totalPrice = price.split('$')[1];
  const priceDecimal = totalPrice.replaceAll(',', '');
  return numberWithCommas(priceDecimal, country);
};

/**
* This function retun the price as a float
* @param {string} price
* @returns {Number} Price
*/
export const getNumericPrice = (price) => {
  if (!price) {
    return '';
  }
  const priceWithoutCommas = price.replace(/,/g, '');
  const totalPrice = parseFloat(priceWithoutCommas);
  return totalPrice * 100;
};

/**
* This function retun the price as a int
* @param {string} price
* @returns {Number} Price
*/
export const getIntPrice = (price) => {
  if (!price) {
    return '';
  }
  const totalPrice = price.split('$')[1];
  const priceWithoutCommas = totalPrice.replace(/,/g, '');
  return parseInt(priceWithoutCommas, 10);
};

/**
* This function retun the price as a int
* @param {string} price
* @returns {Number} Price
*/
export const getNumberWithDecimals = (price) => {
  if (!price) {
    return '';
  }
  const totalPrice = price.split('$')[1];
  const priceWithoutCommas = totalPrice.replace(/,/g, '');
  const numericPrice = +priceWithoutCommas;
  return parseFloat(+numericPrice.toFixed(2));
};

/**
 * Calculates the shipping price based on the currency.
 * @param {number} price - The base price of the shipping.
 * @param {string} country - The country code.
 * @returns {number} - The shipping price converted to the specified currency.
 */
export const getShippingPriceByCurrency = (carts, country) => {
  if (!carts) {
    return '$0';
  }

  const currencyMaps = {
    MX: 'MXN',
    PE: 'PEN',
    CO: 'COP',
    CL: 'CLP',
  };

  const currentCurrency = currencyMaps[country];

  const cart = carts[0];

  const priceInfo = cart.shippingInfo.price;
  if (priceInfo.currencyCode === currentCurrency) {
    return numberWithCommas(priceInfo.centAmount / 100, country);
  }
  return '$0';
};
