/* eslint-disable no-console */
import React, { useEffect, useContext } from 'react';
import GlobalContext from '../../context/Global/Context';
import PaymentMethod from '../PaymentMethod';
import Summary from '../Summary';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  const globalContext = useContext(GlobalContext);
  const { customerId } = globalContext;

  useEffect(() => {
    window.localStorage.setItem('CustomerId', customerId);
    try {
      if (window.dataLayer) {
        console.log('GA4-loadPixel');
        window.dataLayer.push({ event: 'loadPixel' });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className={styles.container}>
      <PaymentMethod />
      <Summary />
    </div>
  );
};

export default Dashboard;
