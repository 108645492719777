/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@mymoons/ui-library';
import { pseLogo, priceCheck } from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import PaymentContext from '../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import AlertModal from '../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import PSEForm from './components/PSEForm';
import { availableCountries } from '../../../../../../utils/locale';
import totalPriceCarts from '../../../../../../hooks/totalCarts';

const FlowPSE = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const [pseLink, setPSELink] = useState('');
  const [formPSE, setFormPSE] = useState(true);
  const [loadingPseButton, setLoadingPseButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const { titlePSE, descriptionPSE, stepsPSE, buttonPSE2 } = data.pse;
  const { country, name, email, customerId, publicKey, origin } = globalContext;
  const { 
    hasAddress, 
    hasMultiplePayments, 
    totalPrice: totalPriceContext, 
    setTotalPrice, 
    setHasMultiplePayments 
  } = paymentContext;
  const {
    carts: cartsProducts,
    couponsCode,
    productsWithDiscount,
  } = productContext;
  let dataToPSE = organizeDataPayments();
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  useEffect(() => {
    if (productsWithDiscount.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-CO',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToPSE.cartData.totalLineItemQuantity = productsArray.length;
      dataToPSE.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  const handleSubmit = () => {
    setLoadingPseButton(true);
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          if (hasMultiplePayments && carts[0].state === 'Active') {
            const remanent = totalPriceCarts(carts, false) - getIntPrice(totalPriceContext);
            setTotalPrice(`$${remanent}`);
            window.history.back();
            return
          }
          setPreventModal(false);
          
          setTimeout(() => {
            window.location.replace(`${window.location.origin}/payment/pse`);
          }, 5000);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  return (
    <>
      {formPSE ? (
        <PSEForm setFormPSE={setFormPSE} setPSELink={setPSELink} />
      ) : (
        <>
          {preventModal && (
            <AlertModal
              data={outOfStockModal}
              modal={false}
              discardOption={false}
            />
          )}
          <div className={styles.titleContainer}>
            <p className={styles.title}>{titlePSE}</p>
            <img
              className={styles.logo}
              width={61}
              height={24}
              src={pseLogo}
              alt="PSE Logo"
            />
          </div>
          <div className={styles.container}>
            <p className={styles.text}>{descriptionPSE}</p>
            <ul className={styles.list}>
              {stepsPSE.map((step, index) => (
                <li key={step} className={styles.text}>
                  <div className={styles.circle}>{index + 1}</div>
                  <div className={styles.stepText}>{step}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.button}>
            <Button
              id="Check_Flow_GoToPSE"
              color="red"
              fullWidth
              isButton
              label={buttonPSE2}
              rel="noopener noreferrer"
              size="medium"
              variant="filled"
              loading={loadingPseButton}
              onClick={() => {
                window.open(pseLink);
                handleSubmit();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FlowPSE;
