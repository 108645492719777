import React, { useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import PaymentContext from '../Context';
import PaymentReducer from '../Reducer';
import {
  SHOW_CLOSE_ADDRESS_MODAL,
  CREATE_ADDRESS,
  HAS_ADDRESS,
  UPDATE_DASHBOARD,
  EDIT_ADDRESS,
  PAYMENT_TYPE,
  SHOWHIDE_PRODUCTS_MOBILE,
  SHOW_CLOSE_CARD_MODAL,
  HAS_CARD,
  TOTAL_PRICE_CARTS,
  HAS_MONTHS,
  SELECT_INSTALMENTS,
  SET_MONTHS,
  SET_INSTALLMENTS_TAX,
  AMOUNT_TO_BE_PAID,
  SET_HAS_MULTIPLE_PAYMENTS,
} from '../types';

const PaymentState = ({ children }) => {
  const localTotalPriceCarts = sessionStorage.getItem('totalPriceCarts');

  const initialstate = {
    address: {},
    totalPriceCarts: localTotalPriceCarts,
    dashboard: 'card',
    modal: false,
    modalType: 'create',
    hasAddress: false,
    createAddress: true,
    editAddress: false,
    selectAddress: false,
    paymentType: 1,
    productsMobile: false,
    cardModal: false,
    cardModalType: 'create',
    hasCard: false,
    createCard: true,
    editCard: false,
    selectCard: false,
    hasMonths: true,
    paymentInstallments: 0,
    months: 1,
    installmentsTax: 0,
    amountToBePaid: 0,
    hasMultiplePayments: false,
  };

  const [state, dispatch] = useReducer(PaymentReducer, initialstate);

  const [shouldResetCardForm, setShouldResetCardForm] = useState(false);

  const resetCardForm = () => {
    setShouldResetCardForm((prev) => { return !prev; });
  };

  const showCloseAddressModal = (modal) => {
    dispatch({
      type: SHOW_CLOSE_ADDRESS_MODAL,
      payload: modal,
    });
  };

  const createAddressFunction = (address) => {
    dispatch({
      type: CREATE_ADDRESS,
      payload: address,
    });
  };

  const setTotalPrice = (total) => {
    sessionStorage.setItem('totalPriceCarts', total);
    dispatch({
      type: TOTAL_PRICE_CARTS,
      payload: localTotalPriceCarts,
    });
  };

  const hasAddressFunction = (value) => {
    dispatch({
      type: HAS_ADDRESS,
      payload: value,
    });
  };

  const hasCardFunction = (value) => {
    dispatch({
      type: HAS_CARD,
      payload: value,
    });
  };

  const updateDashboard = (dashboard) => {
    dispatch({
      type: UPDATE_DASHBOARD,
      payload: dashboard,
    });
  };

  const editAddressFunction = (address) => {
    dispatch({
      type: EDIT_ADDRESS,
      payload: address,
    });
  };

  const changePaymentType = (option) => {
    dispatch({
      type: PAYMENT_TYPE,
      payload: option,
    });
  };

  const showHideProductsMobile = (value) => {
    dispatch({
      type: SHOWHIDE_PRODUCTS_MOBILE,
      payload: value,
    });
  };

  const showCloseCardModal = (value) => {
    dispatch({
      type: SHOW_CLOSE_CARD_MODAL,
      payload: value,
    });
  };

  const hasMonthsFunction = (value) => {
    dispatch({
      type: HAS_MONTHS,
      payload: value,
    });
  };

  const setMonths = (months) => {
    dispatch({
      type: SET_MONTHS,
      payload: months,
    });
  };

  const setInstallmentsTax = (tax) => {
    localStorage.setItem('installmentsTax', tax);
    dispatch({
      type: SET_INSTALLMENTS_TAX,
      payload: tax,
    });
  };

  const selectInstallments = (value) => {
    dispatch({
      type: SELECT_INSTALMENTS,
      payload: value,
    });
  };

  const setAmountToBePaid = (value) => {
    localStorage.setItem('amountToBePaid', value);
    dispatch({
      type: AMOUNT_TO_BE_PAID,
      payload: value,
    });
  };

  const setHasMultiplePayments = (value) => {
    localStorage.setItem('hasMultiplePayments', value);
    dispatch({
      type: SET_HAS_MULTIPLE_PAYMENTS,
      payload: value,
    });
  };

  const contextValue = useMemo(() => {
    return {
      address: state.address,
      totalPrice: localTotalPriceCarts,
      dashboard: state.dashboard,
      modal: state.modal,
      hasAddress: state.hasAddress,
      createAddress: state.createAddress,
      editAddress: state.editAddress,
      selectAddress: state.selectAddress,
      paymentType: state.paymentType,
      productsMobile: state.productsMobile,
      cardModal: state.cardModal,
      cardModalType: state.cardModalType,
      hasCard: state.hasCard,
      createCard: state.createCard,
      editCard: state.editCard,
      selectCard: state.selectCard,
      hasMonths: state.hasMonths,
      months: state.months,
      setMonths,
      installmentsTax: state.installmentsTax,
      setInstallmentsTax,
      showCloseAddressModal,
      createAddressFunction,
      setTotalPrice,
      hasAddressFunction,
      updateDashboard,
      editAddressFunction,
      changePaymentType,
      showHideProductsMobile,
      showCloseCardModal,
      hasCardFunction,
      hasMonthsFunction,
      selectInstallments,
      paymentInstallments: state.paymentInstallments,
      resetCardForm,
      shouldResetCardForm,
      amountToBePaid: state.amountToBePaid,
      setAmountToBePaid,
      hasMultiplePayments: state.hasMultiplePayments,
      setHasMultiplePayments,
    };
  }, [
    state.address,
    localTotalPriceCarts,
    state.dashboard,
    state.modal,
    state.hasAddress,
    state.createAddress,
    state.editAddress,
    state.selectAddress,
    state.paymentType,
    state.productsMobile,
    state.cardModal,
    state.hasCard,
    state.createCard,
    state.editCard,
    state.selectCard,
    state.hasMonths,
    state.months,
    setMonths,
    state.installmentsTax,
    setInstallmentsTax,
    showCloseAddressModal,
    createAddressFunction,
    setTotalPrice,
    hasAddressFunction,
    updateDashboard,
    editAddressFunction,
    changePaymentType,
    showHideProductsMobile,
    showCloseCardModal,
    hasCardFunction,
    hasMonthsFunction,
    selectInstallments,
    state.paymentInstallments,
    shouldResetCardForm,
    state.amountToBePaid,
    setAmountToBePaid,
    state.hasMultiplePayments,
    setHasMultiplePayments,
  ]);

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentState;

PaymentState.propTypes = {
  children: PropTypes.node.isRequired,
};
