import React, { useContext, useEffect } from 'react';
import messages from './data';
import styles from './Address.module.css';
import AddressSelector from './components/AddressSelector';
import PaymentContext from '../../context/Payment/Context';
import AddressModal from './components/AddressModal';
import GlobalContext from '../../context/Global/Context';

const Address = () => {
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const {
    country,
  } = globalContext;
  const { title } = messages[country] || messages.Default;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;
  const {
    modal, hasAddressFunction, hasAddress, address,
  } = paymentContext;

  useEffect(() => {
    if (address.street) {
      hasAddressFunction(true);
    }
  }, [hasAddress]);

  useEffect(() => {
    if (modal) {
      $html.style.height = '100vh';
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      return;
    }

    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, [modal]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <AddressSelector />
      {modal && <AddressModal />}
    </div>
  );
};

export default Address;
