import axios from 'axios';

const {
  REACT_APP_CHECKOUT_API_URL,
} = process.env;

const axiosCreateCharge = async (data) => {
  const response = axios.post(
    `${REACT_APP_CHECKOUT_API_URL}/culqi/create-charge`,
    data,
  );

  return response;
};

const axiosCreateOrder = async (data) => {
  const response = axios.post(
    `${REACT_APP_CHECKOUT_API_URL}/culqi/create-order`,
    data,
  );

  return response;
};

export { axiosCreateCharge, axiosCreateOrder };
