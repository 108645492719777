export const data = {
  titleSelect: 'Tarjetas guardadas',
  titleCreate: 'Nueva tarjeta',
  titleEdit: 'Editar Tarjeta',
  name: 'Nombre del titular de la tarjeta',
  nameHolder: 'Nombre y apellidos',
  info: 'Número de tarjeta',
  cardHolder: '4242 4242 4242 4242',
  monthHolder: '01/29',
  cvcHolder: '1234',
  save: 'Deseo guardar esta tarjeta.',
  list: 'LISTA DE TARJETAS GUARDADAS',
  buttonText: 'Agregar',
  tipoDocumento: 'Tipo de documento',
  numDocumento: 'Número del documento',
  holderDocumento: 'Ingresa el número del documento',
  document: {
    CO: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'Cédula de ciudadanía',
        selected: false,
        value: 'CC',
        regex: 'num',
      },
      {
        id: 2,
        label: 'Cédula de extranjería',
        selected: false,
        value: 'CE',
        regex: 'alfa',
      },
      {
        id: 3,
        label: 'Número de Identificación Tributario',
        selected: false,
        value: 'NIT',
        regex: 'num',
      },
    ],
    PE: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'RUC',
        selected: false,
        value: 'RUC',
        regex: 'num',
      },
      {
        id: 2,
        label: 'DNI',
        selected: false,
        value: 'DNI',
        regex: 'num',
      },
    ],
  },
  clientType: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: null,
    },
    {
      id: 1,
      label: 'Persona Natural',
      selected: false,
      value: 'DNI',
    },
    {
      id: 2,
      label: 'Persona Jurídica',
      selected: false,
      value: 'RUC',
    },
  ],
};

export const paymentInstallments = [
  {
    months: 3,
    min: 299,
    max: 599,
    taxe: 0.0394,
  },
  {
    months: 6,
    min: 600,
    max: 899,
    taxe: 0.0673,
  },
  {
    months: 9,
    min: 900,
    max: 1199,
    taxe: 0.0925,
  },
  {
    months: 12,
    min: 1200,
    max: 1799,
    taxe: 0.1253,
  },
  {
    months: 18,
    min: 1800,
    max: 2399,
    taxe: 0.168,
  },
  {
    months: 24,
    min: 2400,
    max: 100000,
    taxe: 0.2421,
  },
];

export const currencyInstallments = {
  MX: 'MXN',
  CO: 'COP',
  PE: 'PEN',
};

export const mercadoPagoErrorMessages = {
  cc_rejected_bad_filled_card_number: {
    title: 'Número de tarjeta inválido',
    description: 'El número de tarjeta proporcionado no es válido. Por favor, verifica que los dígitos estén correctos e inténtalo nuevamente, o selecciona otro método de pago.',
  },
  cc_rejected_bad_filled_date: {
    title: 'Fecha de vencimiento inválida',
    description: 'La fecha de vencimiento de la tarjeta no es válida. Por favor, verifica que el mes y el año estén correctos e inténtalo nuevamente, o selecciona otro método de pago.',
  },
  cc_rejected_bad_filled_other: {
    title: 'Código de seguridad incorrecto',
    description: 'El código de seguridad (CVV/CVC) proporcionado es incorrecto. Por favor, verifica que el código esté correcto e inténtalo nuevamente, o selecciona otro método de pago.',
  },
  cc_rejected_bad_filled_security_code: {
    title: 'Código de seguridad incorrecto',
    description: 'El código de seguridad (CVV/CVC) proporcionado es incorrecto. Por favor, verifica que el código esté correcto e inténtalo nuevamente o selecciona otro método de pago.',
  },
  cc_rejected_blacklist: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_call_for_authorize: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_card_error: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_other_reason: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  default: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  tokens_error: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_card_disabled: {
    title: 'Tarjeta rechazada',
    description: 'No hemos podido procesar el pago debido a un error del emisor. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_duplicated_payment: {
    title: 'Pago duplicado',
    description: 'Hemos detectado que este pago ya ha sido realizado anteriormente.',
  },
  cc_rejected_high_risk: {
    title: 'Transacción de alto riesgo',
    description: 'Tu tarjeta fue clasificada como de alto riesgo y no ha podido ser procesada. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_insufficient_amount: {
    title: 'Fondos insuficientes',
    description: 'La tarjeta proporcionada no cuenta con fondos suficientes para completar el pago. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_invalid_installments: {
    title: 'Plan de cuotas inválido',
    description: 'El plan de cuotas seleccionado no es válido para esta transacción. Por favor, selecciona otro método de pago.',
  },
  cc_rejected_max_attempts: {
    title: 'Máximo de intentos superado',
    description: 'Se ha superado el máximo de intentos permitidos para procesar este pago. Por favor, selecciona otro método de pago.',
  },
};
