export const COUNTRIES = {
  MX: 'México',
  CO: 'Colombia',
  CL: 'Chile',
  PE: 'Peru',
};

export const setTrackingRudder = (name, data) => {
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.track(name, data);
    }
  } catch {
    // eslint-disable-next-line no-console
    console.log('Error in rudderanalytics');
  }
};

export const setIdentifyRudder = (userId, data) => {
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(userId, data);
    }
  } catch {
    // eslint-disable-next-line no-console
    console.log('Error in rudderanalytics');
  }
};

export default {
  COUNTRIES,
  setTrackingRudder,
  setIdentifyRudder,
};
