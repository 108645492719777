/* eslint-disable */

import React, { useContext, useState, useEffect } from 'react';
import { Button, Input, Dropdown } from '@mymoons/ui-library';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PaymentContext from '../../../../../../context/Payment/Context';
import { data, YupConfigurationByCountry, newAddressValuesbyCountry, valuesNewByCountry, buttonValidationByBountry } from '../../data';
import styles from '../../AddressModal.module.css';
import { axiosPutSetAddress } from '../../../../../../methods/globalData/axiosPut';
import { axiosGetAddressInfo } from '../../../../../../methods/globalData/axiosGet';
import ProductContext from '../../../../../../context/Product/Context';
import statesDataJson from '../../../../../../utils/data.json';
import GlobalContext from '../../../../../../context/Global/Context';

const CreateAddress = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const [statesData, setStateData] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhoodSel, setNeighborhoodSel] = useState('');
  const [stateSelection, setStateSelection] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [enableButton, setEnableButton] = useState(true);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const {
    showCloseAddressModal,
    modal,
    createAddressFunction,
    hasAddressFunction,
  } = paymentContext;
  const { carts } = productContext;
  const cartId = carts[0].id;
  const name = localStorage.getItem('name');
  const emailValue = localStorage.getItem('email');
  const firstNameValue = name.split(' ')[0];
  const lastNameValue = name.split(' ')[1];
  const statesArray = [];
  const globalContext = useContext(GlobalContext);
  const { country, setEmptyAddressFunction, setPhone } = globalContext;

  const validatePhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '').slice(0, 9);

    formik.setFieldValue('Phone', cleanedValue);

    const phoneRegex = /^[0-9]{9}$/;
    const isValid = phoneRegex.test(cleanedValue);
    setPhoneError(!isValid);
  };

  useEffect(() => {
    statesDataJson.forEach((value, index) => {
      const array = {
        id: index,
        label: value.name,
        selected: false,
        value: value.code,
        stateId: value.stateId,
      };
      statesArray.push(array);
    });
    setStateData(statesArray);
    setStateCode(statesArray[0].value);
    setStateSelection(statesArray[0]);
  }, [statesDataJson]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      Street: '',
      Address_Number: '',
      References: '',
      Zip_Code: '',
      State: '',
      Neighborhood: '',
      Department: '',
      Phone: '',
    },
    validationSchema: Yup.object(YupConfigurationByCountry[country]),
    onSubmit: (values) => {
      setLoading(true);
      const generatePersonId = () => {
        const random = Math.random();
        const alphanumeric = random.toString(36);
        return alphanumeric.slice(2);
      };
      const personID = generatePersonId();
      const newAddressValues = {
        country,
        firstNameValue,
        lastNameValue,
        neighborhoodSel,
        stateCode,
        emailValue,
        personID,
        values,
        Phone: formik.values.Phone,
      }
      const valuesNewProperties = {
        country,
        neighborhoodSel,
        stateSelection,
        values
      }
      const newAddress = newAddressValuesbyCountry(newAddressValues)
      const valuesNew = valuesNewByCountry(valuesNewProperties)
      setPhone(formik.values.Phone)
      axiosPutSetAddress(cartId, newAddress)
        .then(() => {
          setLoading(false);
          createAddressFunction(valuesNew);
          setEmptyAddressFunction(false);
          showCloseAddressModal(!modal);
          hasAddressFunction(true);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    setZipCodeError(false);
    if (formik.values.Zip_Code.length == 5) {
      axiosGetAddressInfo(formik.values.Zip_Code)
        .then((res) => {
          const currentState = statesData.filter(state => state.stateId == res.data[0].stateCode);
          setStateCode(statesData[currentState[0].id].value);
          setStateSelection(currentState[0]);
          setLoading(false);

          let neighborhoods = res.data.map(({ objectId, neighborhood }) => {
            return { id: objectId, label: neighborhood, selected: false, value: neighborhood }
          })
          setNeighborhoods(neighborhoods)
          setNeighborhoodSel(neighborhoods[0]);
        })
        .catch((err) => {
          setZipCodeError(true)
          setLoading(false);
          setNeighborhoods([{ id: 0, label: "Seleccionar una opción", selected: true, value: "0" }])
          setNeighborhoodSel({ id: 0, label: "Seleccionar una opción", selected: true, value: "0" });
        });
    } else {
      setNeighborhoods([{ id: 0, label: "Seleccionar una opción", selected: true, value: "0" }])
      setNeighborhoodSel({ id: 0, label: "Seleccionar una opción", selected: true, value: "0" });
    }
  }, [formik.values.Zip_Code]);


  useEffect(() => {
    const valuesToButtonValidation = {
      country,
      formik,
      neighborhoodSel,
      neighborhoods,
      stateSelection,
      zipCodeError,
      phoneError
    }
    if (
      buttonValidationByBountry(valuesToButtonValidation)
    ) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }, [formik.values, stateSelection, neighborhoodSel.value, neighborhoods, zipCodeError, phoneError]);

  useEffect(() => {
    const inputsPattern = document.querySelectorAll('input');

    inputsPattern.forEach((input) => {
      input.removeAttribute('pattern');
    });
  }, []);

  const {
    street,
    streetHolder,
    cp,
    cpHolder,
    numExt,
    numExtHolder,
    numInt,
    numIntHolder,
    suburb,
    suburbHolder,
    state,
    stateHolder,
    buttonText,
    department,
    departmentHolder,
    phoneNumber,
    phoneNumberHolder,
  } = data[country];

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.inputMargin}>
        <Input
          id="Street"
          type="text"
          error={formik.errors.Street ? formik.errors.Street : null}
          label={street}
          placeholder={streetHolder}
          onChange={formik.handleChange}
          value={formik.values.Street}
          variant="outline"
          fullWidth
        />
      </div>
      {phoneNumber && <div className={styles.inputMargin}>
        <Input
          id="Phone"
          type="text"
          error={formik.errors.Phone}
          label={phoneNumber}
          placeholder={phoneNumberHolder}
          onChange={(e) => validatePhone(e.target.value)}
          value={formik.values.Phone}
          onBlur={(e) => validatePhone(e.target.value)}
          variant="outline"
          fullWidth
        />
        {phoneError && (
          <div className="ErrorContainer">
            <span className="material-icons ErrorIcon">error</span>
            <span className="ErrorMessage">
              El número de teléfono debe tener 9 dígitos y solo contener números
            </span>
          </div>
        )}
      </div>}
      <div className={styles.containerNum}>
        <div className={styles.containerNumMargin}>
          <Input
            error={
              formik.errors.Address_Number ? formik.errors.Address_Number : null
            }
            id="Address_Number"
            type="text"
            label={numExt}
            placeholder={numExtHolder}
            onChange={formik.handleChange}
            value={formik.values.Address_Number}
            variant="outline"
            fullWidth
            maxLength={30}
          />
        </div>
        <div>
          <Input
            error={formik.errors.References ? formik.errors.References : null}
            id="References"
            type="text"
            label={numInt}
            placeholder={numIntHolder}
            onChange={formik.handleChange}
            value={formik.values.References}
            variant="outline"
            fullWidth
          />
        </div>
      </div>
      {cp &&
        <div className={styles.inputMargin}>
          <Input
            id="Zip_Code"
            error={formik.errors.Zip_Code ? formik.errors.Zip_Code : null}
            maxLength={5}
            label={cp}
            type="text"
            placeholder={cpHolder}
            onChange={formik.handleChange}
            value={formik.values.Zip_Code}
            variant="outline"
            fullWidth
          />
          {zipCodeError && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                Código postal no valido
              </span>
            </div>
          )}
        </div>}
      <div id="Neighborhood_Input" className={styles.inputMargin}>
        {cp
          ?
          <Dropdown
            className={styles.Dropdown}
            fullWidth
            label={suburb}
            options={neighborhoods}
            value={neighborhoodSel}
            onChange={(selection) => {
              setNeighborhoodSel(selection);
            }}
          />
          :
          <Input
            id="State"
            type="text"
            error={formik.errors.State ? formik.errors.State : null}
            label={state}
            placeholder={stateHolder}
            onChange={formik.handleChange}
            value={formik.values.State}
            variant="outline"
            fullWidth
          />
        }
      </div>
      <div id="State_Input" className={styles.inputMargin}>
        {cp ?
          <Dropdown
            className={styles.Dropdown}
            fullWidth
            label={state}
            options={statesData}
            value={stateSelection}
            // resetThenSet={function noRefCheck() {}}
            // toggleItem={(selection) => setInstallmentSelected(selection)}
            onChange={(selection) => {
              setStateSelection(selection);
              setStateCode(selection.value);
            }}
          />
          :
          <Input
            id="Neighborhood"
            type="text"
            error={formik.errors.Neighborhood ? formik.errors.Neighborhood : null}
            label={suburb}
            placeholder={suburbHolder}
            onChange={formik.handleChange}
            value={formik.values.Neighborhood}
            variant="outline"
            fullWidth
          />
        }

        {/* <Input
          id="State"
          error={
            formik.touched.State && formik.errors.State
              ? formik.errors.State
              : null
          }
          label={state}
          type="text"
          placeholder={stateHolder}
          value={formik.values.State}
          onChange={formik.handleChange}
          
          variant="outline"
          pattern="[a-zA-Z0-9\s]+"
          fullWidth
        /> */}
      </div>
      {department &&
        <div className={styles.inputMargin}>
          <Input
            id="Department"
            type="text"
            error={formik.errors.Department ? formik.errors.Department : null}
            label={department}
            placeholder={departmentHolder}
            onChange={formik.handleChange}
            value={formik.values.Department}
            variant="outline"
            fullWidth
          />
        </div>}
      <Button
        id="Check_NewAddress_CreateAddress"
        color="red"
        isButton
        disabled={enableButton}
        type="submit"
        label={buttonText}
        loading={loading}
        rel="noopener noreferrer"
        size="medium"
        variant="filled"
        fullWidth
      />
    </form>
  );
};

export default CreateAddress;
