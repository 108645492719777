import React, { useContext, useState, useEffect } from 'react';
import { Box, CardProduct, Text } from '../../../../components';
import styles from '../../Summary.module.css';
import {
  productMoons1x,
  productMoons2x,
  productMoons3x,
} from '../../../../assets/images/Global';
import PaymentContext from '../../../../context/Payment/Context';
import useProductDataByCountry from '../../../../hooks/useProductDataByCountry';
import ProductContext from '../../../../context/Product/Context';
import Coupon from '../Coupon';
import convertPrice from '../../../../hooks/convertPrice';
import GlobalContext from '../../../../context/Global/Context';
import Constants from '../../../../utils/Constants';
import { availableCountries } from '../../../../utils/locale';
import { getTotalPrice } from '../../../../utils/cardForm';

const ResumeModalMobile = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const {
    productsMobile,
    showHideProductsMobile,
  } = paymentContext;
  const { products, carts } = productContext;
  const {
    appointmentCenter,
    appointmentDate,
    country,
  } = globalContext;
  const [productsList, setProductsList] = useState([]);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [showProducts, setShowProducts] = useState(false);
  const [productQuantity, setProductQuantity] = useState(0);
  const { assetsURL } = Constants;
  const chevronSvg = `${assetsURL}/svg/chevron.svg`;
  const carSvg = `${assetsURL}/svg/car.svg`;

  const { lineItems } = carts[0];
  let cost = 0;
  let quantity = 0;
  if (productsList.length < products.length) {
    const converted = useProductDataByCountry(products, true, true);
    setProductsList(converted);
  }

  useEffect(() => {
    lineItems.forEach((item) => {
      if (item.price.discounted) {
        cost = +cost + (item.price.discounted.value.centAmount / 100) * item.quantity;
      } else {
        cost = +cost + (item.price.value.centAmount / 100) * item.quantity;
      }
      quantity += item.quantity;
      const priceConv = convertPrice(Number(originalPrice + cost).toFixed(2));
      setOriginalPrice(priceConv);
    });
    setProductQuantity(quantity);
  }, []);

  return (
    <>
      <div className={styles.topContainer}>
        <button type="button" className={styles.collapButton} onClick={() => { setShowProducts(!showProducts); }}>
          <img width={26} height={26} src={carSvg} alt="car" />
          <span className={styles.productQuantity}>{productQuantity}</span>
          <span className={styles.collapTitle}>{showProducts ? 'Ocultar carrito' : 'Ver carrito'}</span>
          <img width={24} height={24} src={chevronSvg} className={showProducts ? styles.chevron : ''} alt="Chevron" />
        </button>
        <span className={styles.totalPrice}>
          {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
          {getTotalPrice(paymentContext, country)}
          {' '}
          {availableCountries[country.toLocaleLowerCase()].currency}
        </span>
      </div>
      <div className={showProducts ? styles.expanded : styles.collapsed}>
        <div>
          <div className={styles.lineModal} />
          <Box
            p={productsList.length === 1 ? '0 16px 16px 16px' : '0 16px 16px 16px'}
          >
            {productsList.map((product) => {
              return (
                <CardProduct
                  key={product.name}
                  img1x={
                    product.images[0] ? product.images[0].url : productMoons1x
                  }
                  img2x={
                    product.images[1] ? product.images[1].url : productMoons2x
                  }
                  img3x={
                    product.images[2] ? product.images[2].url : productMoons3x
                  }
                  title={product.name}
                  principalPrice={getTotalPrice(
                    paymentContext,
                    country,
                  )}
                  quantity={product.quantity}
                  secondaryPrice={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}${getTotalPrice(paymentContext, country)} por unidad`}
                  isPromo={product.hasDiscount}
                  isCitaFlow={(product.productId === process.env.REACT_APP_CITA_ID
                    || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL) && true}
                  appointmentDate={
                    (product.productId === process.env.REACT_APP_CITA_ID
                      || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL)
                    && appointmentDate
                  }
                  appointmentCenter={
                    (product.productId === process.env.REACT_APP_CITA_ID
                      || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL)
                    && appointmentCenter
                  }
                  countryCurrency={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}`}
                />
              );
            })}
          </Box>
          <div className={styles.line} />
          <Box p="0 16px 0 16px">
            <Box fd="row" jc="space-between" p="0 0 16px 0">
              <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                Total en tu carrito
              </Text>
              <Text id="Check_Summary_TotalOriginalPrice" c="#757575" f="Inter Regular" s={14} lh={20}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                { getTotalPrice(paymentContext, country) }
              </Text>
            </Box>
            <Coupon />
          </Box>
          <div className={styles.line} />
          <Box p="0 16px 0px 16px">
            <Box fd="row" jc="space-between">
              <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                Total
              </Text>
              <Text id="Check_Summary_TotalWithDiscount" c="#616161" f="Inter Normal" s={20} lh={20}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                {getTotalPrice(paymentContext, country)}
                {' '}
                {country.toLocaleLowerCase() !== 'pe' ? availableCountries[country.toLocaleLowerCase()].currency : ''}
              </Text>
            </Box>
          </Box>
        </div>
        <button
          id="Check_Summary_CloseProducts_HiddenArea"
          onClick={() => {
            showHideProductsMobile(!productsMobile);
          }}
          type="button"
          aria-label="area"
          className={styles.hiddenButton}
        />
      </div>
    </>
  );
};

export default ResumeModalMobile;
