/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@mymoons/ui-library';
import { spei, priceCheck } from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import axiosPost from '../../../../../../methods/cashPayments/mx/axiosPost';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import PaymentContext from '../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../context/Product/Context';
import AlertModal from '../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import GlobalContext from '../../../../../../context/Global/Context';
import { availableCountries } from '../../../../../../utils/locale';

const FlowSpei = () => {
  const productContext = useContext(ProductContext);
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const globalContext = useContext(GlobalContext);
  const [loadingSpeiButton, setLoadingSpeiButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { titleSpei, descriptionSpei, stepsSpei, buttonSpei } = data.spei;
  const { hasAddress } = paymentContext;
  const { setDataSpei } = thankYouContext;
  const {
    couponsCode,
    productsWithDiscount,
    carts: cartsProducts,
  } = productContext;
  const {
    isCitaFlow,
    isOnlyComplementaryPayment,
    isOnlyAdvance,
    name,
    email,
    customerId,
    country,
    publicKey,
    origin,
    setEmptyAddressFunction,
  } = globalContext;
  let dataToSpei = organizeDataPayments();
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  useEffect(() => {
    if (couponsCode.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-MX',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToSpei.cartData.totalLineItemQuantity = productsArray.length;
      dataToSpei.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  const getSpeiReference = () => {
    if (!isCitaFlow && !isOnlyAdvance && !isOnlyComplementaryPayment) {
      if (!hasAddress) {
        setEmptyAddressFunction(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }

    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );
        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);
          dataToSpei.country = 'México';
          dataToSpei.discountPrice = couponsCode.length > 0 ? true : false;
          dataToSpei.paymentMethodType = 'spei';
          dataToSpei.transactionType = 'product';
          setLoadingSpeiButton(true);
          axiosPost(dataToSpei)
            .then((res) => {
              setDataSpei(res.data);
              setLoadingSpeiButton(false);
              window.location.replace(`${window.location.origin}/payment/spei`);
            })
            .catch(() => {
              setLoadingSpeiButton(false);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.titleContainer}>
        <p className={styles.title}>{titleSpei}</p>
        <img
          className={styles.logo}
          width={68}
          height={16}
          src={spei}
          alt="Spei Logo"
        />
      </div>
      <div className={styles.container}>
        <p className={styles.text}>{descriptionSpei}</p>
        <ul className={styles.list}>
          {stepsSpei.map((step, index) => (
            <li key={step} className={styles.text}>
              <div className={styles.circle}>{index + 1}</div>
              <div className={styles.stepText}>{step}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.button}>
        <Button
          id="Check_Flow_GetReferenceSpei"
          color="red"
          disabled={disableButton}
          fullWidth
          isButton
          label={buttonSpei}
          rel="noopener noreferrer"
          size="medium"
          variant="filled"
          loading={loadingSpeiButton}
          onClick={() => getSpeiReference()}
        />
      </div>
    </>
  );
};

export default FlowSpei;
