import axios from 'axios';

const client = axios.create({ baseURL: process.env.REACT_APP_BLACKBOX_API_URL });

/**
 *
 * @returns {object} Update Deal Data
 */
export const updateDeal = async (body) => {
  const { data } = await client.post('/', body);

  return data;
};

export default updateDeal;
