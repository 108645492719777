export const SET_NAME = 'SET_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_PUBLICKEY = 'SET_PUBLICKEY';
export const SET_IS_CITA_FLOW = 'SET_IS_CITA_FLOW';
export const SET_BUTTON_URL = 'SET_BUTTON_URL';
export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID';
export const SET_CART_COUNTRY = 'SET_CART_COUNTRY';
export const SET_APPOINTMENT_DATE = 'SET_APPOINTMENT_DATE';
export const SET_APPOINTMENT_CENTER = 'SET_APPOINTMENT_CENTER';
export const SET_IS_CITA_WITH_CPG = 'SET_IS_CITA_WITH_CPG';
export const SET_IS_ONLY_CPG = 'SET_IS_ONLY_CPG';
export const SET_IS_ALIGNERS_FLOW = 'SET_IS_ALIGNERS_FLOW';
export const SET_APPOINTMENT_REDIRECT = 'SET_APPOINTMENT_REDIRECT';
export const SET_IS_ONLY_SETTLEMENT = 'SET_IS_ONLY_SETTLEMENT';
export const SET_IS_ONLY_ADVANCE = 'SET_IS_ONLY_ADVANCE';
export const SET_IS_ONLY_COMPLEMENTARY_PAYMENT = 'SET_IS_ONLY_COMPLEMENTARY_PAYMENT';
export const SET_EMPTY_ADDRESS = 'SET_EMPTY_ADDRESS';
export const SET_PHONE = 'SET_PHONE';
export const SET_IS_INFINITY = 'SET_IS_INFINITY';
