import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from '../Context';
import GlobalReducer from '../Reducer';
import {
  SET_NAME,
  SET_EMAIL,
  SET_ORIGIN,
  SET_REDIRECT_URL,
  SET_PUBLICKEY,
  SET_IS_CITA_FLOW,
  SET_BUTTON_URL,
  SET_CUSTOMER_ID,
  SET_CART_COUNTRY,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_CENTER,
  SET_IS_CITA_WITH_CPG,
  SET_APPOINTMENT_REDIRECT,
  SET_IS_ONLY_CPG,
  SET_IS_ALIGNERS_FLOW,
  SET_IS_ONLY_SETTLEMENT,
  SET_IS_ONLY_ADVANCE,
  SET_IS_ONLY_COMPLEMENTARY_PAYMENT,
  SET_EMPTY_ADDRESS,
  SET_PHONE,
  SET_IS_INFINITY,
} from '../types';

const GlobalState = ({ children }) => {
  const initialstate = {
    name: '',
    email: '',
    origin: '',
    redirectUrl: '',
    publicKey: '',
    isCitaFlow: false,
    buttonUrl: '',
    customerId: '',
    country: '',
    appointmentDate: '',
    appointmentCenter: '',
    isCitaWithCPG: false,
    appointmentRedirect: false,
    isOnlyCPG: false,
    isAlignersFlow: false,
    isOnlySettlement: false,
    isOnlyAdvance: false,
    isOnlyComplementaryPayment: false,
    emptyAddress: false,
    phone: '',
    isInfinity: false,
  };

  const [state, dispatch] = useReducer(GlobalReducer, initialstate);

  const setNameFunction = (name) => {
    dispatch({
      type: SET_NAME,
      payload: name,
    });
  };

  const setEmailFunction = (email) => {
    dispatch({
      type: SET_EMAIL,
      payload: email,
    });
  };

  const setOriginFunction = (origin) => {
    localStorage.setItem('origin', origin);
    dispatch({
      type: SET_ORIGIN,
      payload: origin,
    });
  };

  const setRedirectUrlFunction = (url) => {
    localStorage.setItem('redirectUrl', url);
    dispatch({
      type: SET_REDIRECT_URL,
      payload: url,
    });
  };

  const setPublicKeyFunction = (publicKey) => {
    localStorage.setItem('publicKey', publicKey);
    dispatch({
      type: SET_PUBLICKEY,
      payload: publicKey,
    });
  };

  const setIsCitaFlowFunction = (value) => {
    localStorage.setItem('isCitaFlow', value);
    dispatch({
      type: SET_IS_CITA_FLOW,
      payload: value,
    });
  };

  const setButtonUrlFunction = (url) => {
    localStorage.setItem('buttonUrl', url);
    dispatch({
      type: SET_BUTTON_URL,
      payload: url,
    });
  };

  const setCustomerIdFunction = (customer) => {
    dispatch({
      type: SET_CUSTOMER_ID,
      payload: customer,
    });
  };

  const setCartCountryFunction = (country) => {
    localStorage.setItem('country', country);
    dispatch({
      type: SET_CART_COUNTRY,
      payload: country,
    });
  };

  const setAppointmentDateFunction = (date) => {
    localStorage.setItem('appointmentDate', date);
    dispatch({
      type: SET_APPOINTMENT_DATE,
      payload: date,
    });
  };

  const setAppointmentCenterFunction = (center) => {
    localStorage.setItem('appointmentCenter', center);
    dispatch({
      type: SET_APPOINTMENT_CENTER,
      payload: center,
    });
  };

  const setIsCitaWithCPGFunction = (citaWithCPG) => {
    localStorage.setItem('isCitaWithCPG', citaWithCPG);
    dispatch({
      type: SET_IS_CITA_WITH_CPG,
      payload: citaWithCPG,
    });
  };

  const setAppointmentRedirectFunction = (redirect) => {
    dispatch({
      type: SET_APPOINTMENT_REDIRECT,
      payload: redirect,
    });
  };

  const setIsOnlyCPGFuntction = (onlyCPG) => {
    localStorage.setItem('isOnlyCPG', onlyCPG);
    dispatch({
      type: SET_IS_ONLY_CPG,
      payload: onlyCPG,
    });
  };

  const setIsAlignersFlowFunction = (aligners) => {
    localStorage.setItem('isAlignersFlow', aligners);
    dispatch({
      type: SET_IS_ALIGNERS_FLOW,
      payload: aligners,
    });
  };

  const setIsOnlySettlementFunction = (settlement) => {
    localStorage.setItem('isOnlySettlement', settlement);
    dispatch({
      type: SET_IS_ONLY_SETTLEMENT,
      payload: settlement,
    });
  };

  const setIsOnlyAdvanceFunction = (advance) => {
    localStorage.setItem('isOnlyAdvance', advance);
    dispatch({
      type: SET_IS_ONLY_ADVANCE,
      payload: advance,
    });
  };

  const setIsOnlyComplementaryPaymentFunction = (complementaryPayment) => {
    localStorage.setItem('isOnlyComplementaryPayment', complementaryPayment);
    dispatch({
      type: SET_IS_ONLY_COMPLEMENTARY_PAYMENT,
      payload: complementaryPayment,
    });
  };

  const setEmptyAddressFunction = (value) => {
    dispatch({
      type: SET_EMPTY_ADDRESS,
      payload: value,
    });
  };

  const setPhone = (value) => {
    dispatch({
      type: SET_PHONE,
      payload: value,
    });
  };

  const setIsInfinity = (value) => {
    localStorage.setItem('isInfinity', value);
    dispatch({
      type: SET_IS_INFINITY,
      payload: value,
    });
  };

  const contextValue = useMemo(() => {
    return {
      name: state.name,
      email: state.email,
      origin: state.origin,
      redirectUrl: state.redirectUrl,
      publicKey: state.publicKey,
      isCitaFlow: state.isCitaFlow,
      buttonUrl: state.buttonUrl,
      customerId: state.customerId,
      country: state.country,
      appointmentDate: state.appointmentDate,
      appointmentCenter: state.appointmentCenter,
      isCitaWithCPG: state.isCitaWithCPG,
      appointmentRedirect: state.appointmentRedirect,
      isOnlyCPG: state.isOnlyCPG,
      isAlignersFlow: state.isAlignersFlow,
      isOnlySettlement: state.isOnlySettlement,
      isOnlyAdvance: state.isOnlyAdvance,
      isOnlyComplementaryPayment: state.isOnlyComplementaryPayment,
      emptyAddress: state.emptyAddress,
      phone: state.phone,
      isInfinity: state.isInfinity,
      setNameFunction,
      setEmailFunction,
      setOriginFunction,
      setRedirectUrlFunction,
      setPublicKeyFunction,
      setIsCitaFlowFunction,
      setButtonUrlFunction,
      setCustomerIdFunction,
      setCartCountryFunction,
      setAppointmentDateFunction,
      setAppointmentCenterFunction,
      setIsCitaWithCPGFunction,
      setAppointmentRedirectFunction,
      setIsOnlyCPGFuntction,
      setIsAlignersFlowFunction,
      setIsOnlySettlementFunction,
      setIsOnlyAdvanceFunction,
      setIsOnlyComplementaryPaymentFunction,
      setEmptyAddressFunction,
      setPhone,
      setIsInfinity,
    };
  }, [
    state.name,
    state.email,
    state.origin,
    state.redirectUrl,
    state.publicKey,
    state.isCitaFlow,
    state.buttonUrl,
    state.customerId,
    state.country,
    state.appointmentDate,
    state.appointmentCenter,
    state.isCitaWithCPG,
    state.appointmentRedirect,
    state.isOnlyCPG,
    state.isAlignersFlow,
    state.isOnlySettlement,
    state.isOnlyAdvance,
    state.isOnlyComplementaryPayment,
    state.emptyAddress,
    state.phone,
    state.isInfinity,
    setNameFunction,
    setEmailFunction,
    setOriginFunction,
    setRedirectUrlFunction,
    setPublicKeyFunction,
    setIsCitaFlowFunction,
    setButtonUrlFunction,
    setCustomerIdFunction,
    setCartCountryFunction,
    setIsCitaWithCPGFunction,
    setAppointmentRedirectFunction,
    setIsOnlyCPGFuntction,
    setIsAlignersFlowFunction,
    setIsOnlySettlementFunction,
    setIsOnlyAdvanceFunction,
    setIsOnlyComplementaryPaymentFunction,
    setEmptyAddressFunction,
    setPhone,
    setIsInfinity,
  ]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;

GlobalState.propTypes = {
  children: PropTypes.node.isRequired,
};
