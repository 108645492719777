/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button, Dropdown, Input } from '@mymoons/ui-library';
import { mercadoPago, mercadoPagoMobile, priceCheck } from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import axiosPost from '../../../../../../methods/cashPayments/mx/axiosPost';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import PaymentContext from '../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import AlertModal from '../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import totalPriceCarts from '../../../../../../hooks/totalCarts';
import { availableCountries } from '../../../../../../utils/locale';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';

const FlowEfectivo = () => {
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const [loadingEfectivoButton, setLoadingEfectivoButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [hasErrorDocumentNumber, setHasErrorDocumentNumber] = useState(null);
  const { titleEfectivo, descriptionEfectivo, stepsEfectivo, buttonEfectivo } =
    data.efectivo;
  const [valuesSelected, setValuesSelected] = useState({
    document: {},
    clientType: {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: null,
    },
    number: '',
    isValid: false,
  });
  const {
   
    name,
    email,
    customerId,
    publicKey,
    origin,
    setEmptyAddressFunction,
  } = globalContext;
  var country = 'pe';
  const { setDataEfectivo } = thankYouContext;
  const { hasAddress } = paymentContext;
  const {
    carts: cartsProducts,
    couponsCode,
    productsWithDiscount,
  } = productContext;
  let dataToEfectivo = organizeDataPayments();
  const totalPrice = totalPriceCarts(cartsProducts, false);
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  const isMobile = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    if (productsWithDiscount.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-CO',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToEfectivo.cartData.totalLineItemQuantity = productsArray.length;
      dataToEfectivo.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  const validateDocumentNumber = (value) => {
    let isValid = false;
    let maxLength = 20;
    let errorMessage = '';
    let formattedValue = value.replace(/[^0-9]/g, '');

    const clientType = valuesSelected.clientType.value;
    const regexRUC = /^([1-2]{1}[0-9]{10})$/;
    const regexDNI = /^\d{8}$/;

    switch (clientType) {
      case 'DNI':
        isValid = regexDNI.test(formattedValue);
        maxLength = 8;
        errorMessage = 'Ingrese un DNI válido (8 dígitos)';
        break;
      case 'RUC':
        isValid = regexRUC.test(formattedValue);
        maxLength = 11;
        errorMessage = 'Ingrese un RUC válido (11 dígitos, comenzando con 1 o 2)';
        break;
      default:
        isValid = true;
    }

    setValuesSelected(prev => ({
      ...prev,
      number: formattedValue,
      isValid: isValid,
      errorMessage: !isValid && formattedValue !== '' ? errorMessage : ''
    }));

    setHasErrorDocumentNumber(!isValid && formattedValue !== '');

    return maxLength;
  };

  const getEfectivoReference = () => {

    if (!hasAddress) {
      setEmptyAddressFunction(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);

          dataToEfectivo.country = 'Peru';
          dataToEfectivo.discountPrice = couponsCode.length > 0 ? true : false;
          dataToEfectivo.paymentMethodType = 'pagoEfectivo';
          dataToEfectivo.transactionType = 'product';
          dataToEfectivo.productKeyName =
            cartsProducts[0]?.lineItems[0]?.productKey;
          dataToEfectivo.payer.identification.docTypeCard = valuesSelected.clientType.value;
          dataToEfectivo.payer.identification.docValueCard = valuesSelected.number;
          setLoadingEfectivoButton(true);
          axiosPost(dataToEfectivo)
            .then((res) => {
              setDataEfectivo(res.data);
              setLoadingEfectivoButton(false);
              window.location.replace(res.data.mercadoPagoLink);
            })
            .catch(() => {              
              setLoadingEfectivoButton(false);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  useEffect(() => {
    if (totalPrice >  10000) {
      setDisableButton(true);
    }
  }, [totalPrice]);

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.titleContainer}>
        <p className={styles.title}>{titleEfectivo}</p>
        {!isMobile ? (
          <img
          className={styles.logo}
          width={91}
          src={mercadoPago}
          alt="Efectivo Logo"
          />
        ):(
          <img
            className={styles.logo}
            width={33.5}
            src={mercadoPagoMobile}
            alt="Efectivo Logo"
          />
        )}
       
      </div>
      <div className={styles.container}>
      {(
          <>
            <div className={styles.inputMargin}>
            <Dropdown
              id="document_client"
              fullWidth
              label="Tipo de cliente"
              options={data.clientType}
              value={valuesSelected.clientType}
              onChange={(selection) => {
                if (selection.id !== 0) {
                  setValuesSelected({
                    ...valuesSelected,
                    clientType: {
                      id: selection.id,
                      label: selection.label,
                      selected: true,
                      value: selection.value,
                    },
                  });
                }
              }}
            />
            </div>
            <br />
            <div className={styles.inputMargin}>
              <Input
                label="Número de documento"
                disabled={valuesSelected.clientType.id === 0}
                placeholder={data.holderDocumento}
                value={valuesSelected.number}
                onChange={(e) => {
                  const maxLength = validateDocumentNumber(e.target.value);
                  e.target.maxLength = maxLength;
                }}
                variant="outline"
                pattern={null}
                fullWidth
              />
            </div>
            <br />
            {valuesSelected.errorMessage && (
              <div className={styles.inputMargin}>
                <div className="ErrorContainer">
                  <span className="material-icons ErrorIcon">error</span>
                  <span className="ErrorMessage">{valuesSelected.errorMessage}</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.container}>
        <p className={styles.text}>{descriptionEfectivo}</p>
        <ul className={styles.list}>
          {stepsEfectivo.map((step, index) => (
            <li key={step} className={styles.text}>
              <div className={styles.circle}>{index + 1}</div>
              <div className={styles.stepText}>{step}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.button}>
        <Button
          id="Check_Flow_GetReferenceOxxo"
          color="red"
          disabled={disableButton || !valuesSelected.isValid}
          fullWidth
          isButton
          label={buttonEfectivo}
          rel="noopener noreferrer"
          size="medium"
          variant="filled"
          loading={loadingEfectivoButton}
          onClick={() => getEfectivoReference()}
        />
      </div>
    </>
  );
};

export default FlowEfectivo;
